.teaser {
	$self: &;

	$sizeDefault: '.teaser--size-default';
	$sizeMedium: '.teaser--size-med';
	$sizeBig: '.teaser--size-big';

	$typeDefault: #{$self}--default;
	$typeBlog: #{$self}--blog;
	$typeCasestudy: #{$self}--casestudy;
	$typeWhitepaper: #{$self}--whitepaper;
	$typeSuccesstories: #{$self}--successtories;
	$typeManual: #{$self}--manual;
	$typeManualTabs: #{$self}--manual-tabs;
	$typeManualRedesignBig: #{$self}--manual-redesign-big;
	$typeManualRedesignText: #{$self}--manual-redesign-text;

	width: 100%;
	color: rgba(var(--theme-color-font-std), 1);

	&--default,
	&--blog {
		//default and blog are the same

		&:hover {
			color: rgba(var(--theme-color-font-link), 1);
		}


		&#{$self}--size-big {
			@include respond-to(tablet) {
				display: grid;

				grid-template-columns: 50% 50%;
				grid-template-rows: auto;

				grid-template-areas: 'image content';
			}

			@include respond-to(display) {
				grid-template-columns: auto 25.4%;
				grid-template-rows: 50rem;
			}


		}

	}

	&--video,
	&--usecases,
	&--whitepaper,
	&--ebook,
	&--webinar,
	&--casestudy{
		&:hover {
			color: rgba(var(--theme-color-font-link), 1);
		}
	}

	&--usecases {}
	&--whitepaper {}
	&--webinar {}
	&--ebook {}
	&--video {}

	&--size-default {}
	&--size-med {}
	&--size-big {}

	&--manual {
		display: grid;
		grid-template-rows: 20vh auto;
		grid-template-columns: 1fr;
		grid-template-areas: 'image' 'main';

		@include respond-to(tablet_landscape) {
			grid-template-rows: auto;
			grid-template-areas: 'main';
		}
	}

	&--manual-tabs {
		--border-corner-radius: 4rem;


		&:hover {
			color: rgba(var(--theme-color-font-link), 1);
		}

		grid-template-rows: auto 31rem;
		grid-template-columns: 1fr;
		grid-template-areas: 'image' 'main';

		border-radius: var(--border-corner-radius);
		box-shadow: var(--theme-box-shadow);

		overflow: hidden;

		&#{$self}--no-image {
			grid-template-rows: auto;
			grid-template-areas: 'main';

			--border-corner-radius: 2rem;
		}
	}

	&--manual-redesign-big {

	}


	&__image {
		//--blendmode: multiply; //luminosity; // multiply;
		--blendmode: normal;
		position: relative;


		.image {
			transition: background $tt-std ease;
			z-index: 0;
			overflow: hidden;
		}

		.image__wrapper {
			transition: transform 800ms ease;
			&.lazy--loaded {
				mix-blend-mode: var(--blendmode);
			}
		}



		//#{$self}--usecases:hover &,
		//#{$self}--casestudy:hover &,
		//#{$self}--whitepaper:hover & {
		//	.image {
		//		background: rgba(var(--sc), 1);
		//	}
		//}

		#{$self}--blog:hover &,
		#{$self}--video:hover &,
		#{$self}--usecases:hover &,
		#{$self}--casestudy:hover &,
		#{$self}--whitepaper:hover &
		{
			.image__wrapper {
				transform: scale(1.1);
			}
		}

		#{$self}--blog#{$sizeBig} & {
			grid-area: image;

			.image,
			.image__wrapper {
				height: 100%;
			}

			IMG, PICTURE {
				@extend %object-fit-cover;
			}
		}

		//#{$self}--usecases &,
		//#{$self}--casestudy & {
		//	background: rgba(var(--theme-color-white-real), 1);
		//	--blendmode: multiply;
		//}
		//
		//#{$self}--whitepaper & {
		//	--blendmode: luminosity;
		//}
		

		#{$self}--manual & {
			grid-area: image;
			@include respond-to(tablet_landscape) {
				grid-area: main;
			}

			position: relative;
			z-index: 0;

			.image__wrapper,
			.image {
				width: 100%;
				height: 100%;
			}

			IMG {
				object-fit: cover;

			}
		}

		#{$self}--manual-tabs & {
			grid-area: image;
			background: rgba(var(--theme-color-white-real), 1);
		}

	}

	&__content-wrapper {
		#{$self}--blog#{$self}--size-big & {


			@include respond-to(tablet) {
				position: relative;
				grid-area: content;
				padding: 0 3.5rem;
				background: rgba(var(--theme-color-green-light), 1);
			}
		}

		//#{$self}--usecases &,
		//#{$self}--casestudy &,
		//#{$self}--whitepaper & {
		//	padding: 0 2.5rem 2.5rem;
		//}

		//#{$self}--usecases#{$sizeDefault} &,
		//#{$self}--casestudy#{$sizeDefault} &,
		//#{$self}--whitepaper#{$sizeDefault} & {
		//	padding: 0 1.5rem 2.5rem;
		//}

		//#{$self}--casestudy & {
		//	background: rgba(var(--theme-color-aubergine), 1);
		//}
		//#{$self}--whitepaper & {
		//	background: rgba(var(--theme-color-white-real), 1);
		//
		//}

		#{$self}--manual & {
			--o-button-color: var(--theme-color-red);
			--o-button-font-color-hover: var(--theme-color-white-real);

			grid-area: main;
			align-self: flex-end;
			justify-self: flex-end;
			background: rgba(var(--theme-color-white-real), 1);

			position: relative;
			z-index: 10;
			width: 100%;
			@include respond-to(tablet_landscape) {
				width: 50%;
			}
			padding: 0 var(--layout-gutter);

			--fc: var(--theme-color-aubergine);
			--bc: var(--theme-color-red);
			--bbc: var(--bc);
		}

		#{$self}--manual-tabs & {
			width: 100%;
			height: 100%;

			padding-bottom: calc(var(--border-corner-radius) - 0.5rem);
			border-bottom-left-radius: var(--border-corner-radius);
			border-bottom-right-radius: var(--border-corner-radius);


		}

		#{$self}--manual-tabs#{$self}--no-image & {
			padding-top: calc(var(--border-corner-radius) - 0.5rem);
			background: rgba(var(--theme-color-white-real), 1);
		}
	}

	&__symbol {
		transform: translateY(30%);
		position: absolute;
		bottom:0;
		left: 2.5rem;

		z-index: 10;
		height: 2rem;
		svg {
			width: auto;
			height: inherit;

			fill: rgba(var(--sc, var(--theme-color-aubergine)), 1);
			stroke: rgba(var(--bgc, var(--theme-color-body)), 1);
		}

		#{$self}--size-default & {
			left: 1.5rem;
		}

		#{$self}--size-big & {
			@include respond-to(tablet) {
				transform: translateX(50%);
				top: 3rem;
				left: initial;
				right: 0;
			}
		}

		#{$self}--hide-icon & {
			visibility: hidden;
			opacity: 0;

		}
	}

	&__category {
		--c: var(--scf, var(--sc, var(--theme-color-font-std)));

		@include font-style-category;
		padding: 3rem 0 1.5rem;
		color: rgba(var(--c), 1);

		#{$self}--size-default & {
			font-size: 0.8em;
			padding: 2rem 0 0.4rem;
		}

		#{$self}--manual-tabs & {
			display: none;
		}
	}

	&__content {
		@include respond-to(tablet) {
			padding-right: 16.66%;
		}

		#{$self}--size-big &,
		#{$self}--size-default & {
			padding-right: 0;
		}

		#{$self}--manual-tabs & {
			height: 100%;
			overflow: hidden;
		}

		#{$self}--manual-tabs#{$self}--c3 & {
			padding-right: var(--layout-gutter);
		}

	}

	&__title {
		@include font-style-headline-2;
		margin: 0.4em 0;

		#{$self}--size-big &,
		#{$self}--size-default & {
			@include font-style-headline-3;
		}

		#{$self}--size-default & {
			font-weight: $font-weight-regular;
		}

		#{$self}--manual & {
			margin-top:0;
		}

		#{$self}--manual-tabs & {
			@include font-style-headline-3;
			color: rgba(var(--theme-color-red), 1);
			margin-top:0.6em;


		}

		#{$self}--no-image & {
			@include font-style-headline-4;
		}


	}

	&__text {
		//#{$self}--whitepaper &,
		//#{$self}--usecases &,
		//#{$self}--casestudy & {
		//	display: none;
		//}

		#{$self}--manual-tabs & {
			P:last-child {
				margin-bottom: 0;
			}
		}

		#{$self}--no-image & {
			@include font-style-body-small;
		}
	}

	&__link-icon {
		transition: all $tt-std ease;
		display: inline-block;
		margin: 1em 0 0;


		#{$self}--size-default &,
		#{$self}--manual-tabs & {
			svg {
				transform: rotate(90deg);
				width: 2.5rem;
				height: auto;
				fill: rgba(var(--theme-color-red), 1);
			}
		}

		#{$self}--size-default & {
			margin: 0;
			transform: translateY(0.5rem);
		}

		#{$self}:hover & {
			transform: translateX(50%);
		}

		#{$self}--size-default:hover & {
			transform: translateY(0.5rem) translateX(50%);
		}
	}

	&__bookmark {
		position: absolute;
		right: 3.5rem;
		top: -0.5rem;
		user-select: none;
		cursor: default;

		@include respond-to(tablet) {

		}

		IMG {
			width: auto;
			height: auto;
		}
	}
}

.teaser-redesign {
	$self: &;

	display: grid;

	//cursor: pointer;
	user-select: none;


	&--big {

		grid-template-columns:1fr;
		grid-template-areas:
			'image'
			'content'
		;

		@include respond-to(until_tablet) {
			grid-template-rows: auto auto;
		}

		@include respond-to(tablet) {
			grid-template-columns: 50% 1fr;
			grid-template-areas: 'image content';
		}

		@include respond-to(tablet_landscape) {
			grid-template-columns: 74.65% 1fr;
		}
		grid-column-gap: var(--layout-gutter);


		background: rgba(var(--bgc, var(--theme-color-red)), 1);
		//color: rgba(var(--fc, var(--theme-color-white-real)), 1);
		color: var(--fc, rgba(var(--theme-color-white-real), 1));
	}

	&--text {
		grid-template-columns: 100%;
		grid-template-areas: 'content';
		color: rgba(var(--theme-color-font-std), 1);

		.grid--t50 & {
			margin-bottom: var(--layout-grid-spacing);
		}

		@include respond-to(tablet) {
			.grid--t50 & {
				display: inline-flex;
				width: calc(50% - var(--layout-gutter));
				margin-bottom: 0;
				//margin-bottom: var(--layout-gutter);

				&:nth-of-type(3n+4),
				&:nth-of-type(3n+5) {
					margin-top: var(--layout-grid-spacing);
					margin-bottom: 0;
				}
			}
		}
	}

	&--card,
	&--card-simple {
		transition: all $tt-std ease;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'image'
			'content'
		;

		color: rgba(var(--theme-color-font-std), 1);
		border: 0.2rem solid rgba(var(--theme-color-green-2), 1);
		background: transparent;
		text-align: center;
		padding: 6.5rem var(--layout-grid-spacing);
		//height: 100%;


		&:hover {
			//border-color: rgba(var(--theme-color-green-light),1);
			background: rgba(var(--theme-color-green-light),1);
		}
	}

	&--card-simple {
		grid-template-rows: min-content auto;

		height: 100%;
		border: 0;
		text-align: left;
		background: rgba(var(--theme-color-white-real), 1);
		padding: 4.5rem 4rem 3.5rem;
		&:hover {
			background: rgba(var(--theme-color-white-real), 1);
		}
	}

	&--cardbg {

		--o-button-color: var(--i-bc);

		background-color: rgba(var(--bgc), 1);
		color: var(--fc);

		grid-template-columns: var(--layout-gutter) auto var(--layout-gutter) ;
		grid-template-rows: auto var(--layout-gutter) auto var(--layout-gutter);
		grid-template-areas:
			'image image image'
			'. . .'
			'. content .'
			'. . . '
		;

		&[data-col="12"] {

			grid-template-columns: var(--layout-gutter) 70% var(--layout-gutter) auto;

			@include respond-to(tablet) {
				grid-template-columns: 5rem 40% var(--layout-gutter) auto;
			}
			grid-template-rows: auto;
			grid-template-areas: ' . content . image';

		}

	}

	&__image {
		position: relative;
		grid-area: image;

		#{$self}--big & {
			width: 100%;
			height: 100%;
			overflow: hidden;

			.image,
			.image__wrapper {
				height: 100%;
			}

			IMG, PICTURE {

				@include respond-to(tablet) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
				@include respond-to(display) {
					object-fit: unset;
					width:100%;
					height: auto;

				}

			}
		}

		#{$self}--cardbg & {
			grid-area: image;
		}

		#{$self}--card & {
			margin-bottom: 4rem;
		}
		#{$self}--card-simple & {
			margin-bottom: 4rem;

			.image,
			.image__wrapper {
				height: 100%;

				aspect-ratio: unset !important;
			}

			IMG, PICTURE {

				object-fit: contain;
				width: 100%;
				height: 100%;

				aspect-ratio: unset !important;

				//@include respond-to(tablet) {
				//	width: 100%;
				//	height: 100%;
				//	object-fit: cover;
				//	object-position: center;
				//}
				//@include respond-to(display) {
				//	object-fit: unset;
				//	width:100%;
				//	height: auto;
				//
				//}

			}
		}

		#{$self}--cardbg & {
			//height: 2000px;
		}
	}

	&__image-wrapper {
		#{$self}--big & {

			@include respond-to(tablet) {
				height: 100%;
			}
			@include respond-to(display) {
				height: auto;
			}


			//.layout--livemode & {
			//	position: absolute;
			//	top: 0;
			//	left: 0;
			//	right: 0;
			//	bottom: 0;
			//}
		}

		#{$self}--cardbg & {
			height:100%;

			.image,
			.image__wrapper {
				width:100%;
				height:100%;
			}

			.image__image {
				object-fit: cover;
				object-position: left center;
			}
		}

		#{$self}--card-simple & {


			margin: 0 auto;

			.layout--editmode & {
				width: 55%;
			}

			.layout--livemode & {
				height:16rem;
			}
		}
	}



	&__content {
		grid-area: content;

		#{$self}--big & {
			padding-top: calc(var(--layout-grid-spacing) / 2);
			padding-bottom: calc(var(--layout-grid-spacing) / 3);
			padding-right: var(--layout-gutter);
			padding-left: var(--layout-gutter);

			@include respond-to(tablet) {
				padding-left: 0;

				display: flex;
				flex-direction: column;
				justify-content: center;

			}
		}

		#{$self}--text & {
			transition: all $tt-std ease;
			position: relative;
			padding: 0 var(--layout-gutter);

			&:before {

				transition: background-color $tt-std ease;
				content: '';
				position: absolute;
				top:0;
				left:0;
				bottom:0;
				width: 0.2rem;
				background: rgba(var(--theme-color-green-grey), 1);
			}

			@include respond-to(tablet) {
				.grid--t50 & {
					padding-right: 0;
				}
			}
		}

		#{$self}--text:hover & {
			color: rgba(var(--theme-color-red), 1);
			&:before {
				background: rgba(var(--theme-color-red), 1);
			}
		}

		#{$self}--card &,
		#{$self}--card-simple & {
			width: 100%;
			overflow: hidden;

			.button {
				@include respond-to(until_display) {
					width: 100%;
				}
			}
		}

		#{$self}--card-simple & {
			p {
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;

				&:first-child {
					margin-top:0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		#{$self}--cardbg & {
			align-self: center;
			display: flex;
			align-items: center;
		}

		#{$self}--cardbg[data-col="12"] & {
			align-self: center;
			display: block;
			margin: 4rem 0;
		}

		#{$self}--cardbg[data-col]:not([data-col="12"]) & {
			color: rgba(var(--theme-color-font-link), 1);
		}


	}

	&__text {
		#{$self}--text &,
		#{$self}--card & {
			@include font-style-body-lead;
		}

		#{$self}--card-simple & {
			@include font-style-body;
		}


		#{$self}--text & {

			display: inline;
		}

		#{$self}--card & {
			margin-bottom: 4rem;
		}

	}

	&__link-icon {
		transition: all $tt-std ease;
		display: inline-block;
		margin: 1em 0 0;

		svg {
			transform: rotate(90deg);
			width: 2.5rem;
			height: auto;
			fill: rgba(var(--theme-color-white-real), 1);
		}

		#{$self}:hover & {
			transform: translateX(50%);
		}

		#{$self}--big & {
			svg {
				fill: rgba(var(--lfc, var(--theme-color-white-real)), 1);
			}
		}

		#{$self}--text & {
			transform: translateY(0.5rem);
			display: inline-flex;
			align-items: center;
			margin:0;

			svg {
				fill: rgba(var(--theme-color-red), 1);
			}
		}

		#{$self}--text:hover & {
			transform: translateX(50%) translateY(0.5rem);
		}

		#{$self}--cardbg & {
			margin-top: 1rem;
			margin-left: 1rem;
			svg {
				fill: currentColor;
			}
		}
	}

}

.teaser-career {
	$self: &;



	position: relative;
	display: block;
	width: 100%;

	--background-color: rgba(var(--theme-color-green-light), 1);
	--color: rgba(var(--theme-color-font-std), 1);
	--color-alternate: rgba(var(--theme-color-font-link), 1);
	--color-dot: rgba(var(--theme-color-green), 1);

	color: var(--color);

	&:hover {
		--background-color: rgba(var(--theme-color-font-link) ,1);
		--color-alternate: rgba(var(--theme-color-white), 1);
		--color-dot: var(--color-alternate);
	}

	&:before {
		transition: all $tt-std ease;

		display: block;
		content: "";
		width: 100%;
		padding-top: (160 / 233) * 100%;

		background: var(--background-color);
	}

	&__inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: var(--layout-gutter);

		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: 3rem 1fr 5rem;
	}

	&__subtitle,
	&__title,
	&__location {
		transition: all $tt-std ease;
	}

	&__subtitle,
	&__location {
		@include font-style-body-small;
	}

	&__title {
		@include font-style-headline-2;
		color: var(--color-alternate);
	}

	&__location {
		display: flex;
		align-items: center;
		align-self: flex-end;
		font-weight: $font-weight-bold;

		position: relative;
		&:before {
			content: '';
			width: 1rem;
			height: 1rem;
			border-radius: 1rem;
			margin-right: 1rem;
			margin-bottom: 0.2rem;
			background: var(--color-dot);
		}
	}

}