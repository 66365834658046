.animation {
	$self: &;

	position: relative;
	width: 100%;

	&--dropshadow {
		box-shadow: -0.1rem 2.2rem 4.3rem -2.0rem rgba(0,0,0,0.35);

	}

	&--bg {
		background: rgba(var(--bgc, var(--theme-color-white-real)), 1);
	}

	&__view {
		width: 100%;
	}

	.layout--livemode & {
		@supports (--custom:property) {
			&[style*="--aspect-ratio"] {
				&:before {
					content: '';
					display: block;
					padding-bottom: calc(100% / (var(--aspect-ratio) + 0));
				}

				& > :first-child {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
				}
			}
		}
	}
}