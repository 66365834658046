.video {
	$self: &;

	position: relative;
	margin-bottom: var(--layout-gutter);

	&--youtube {

		.layout--livemode & {

			&:before {
				content: "";
				display: block;
				padding-top: 56%; /* 16:9 Format */
			}

			IFRAME {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

	&__wrapper {
	}

	&__controls {
		transition: all $tt-std ease;
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		background: rgba(var(--theme-color-grey), 0.3);

		#{$self}--playing & {
			opacity: 0;
			pointer-events: none;
			//&:hover {
			//	opacity: 0.8;
			//}
		}
	}

	&__control {
		appearance: none;
		background: none;
		border:0;
		cursor: pointer;


		&--play-pause {
			transition: all $tt-std ease;
			width: 8rem;
			height: 8rem;
			background: rgba(var(--theme-color-grey-3), 0.8);
			border: 0.2rem solid rgba(var(--theme-color-grey-2), 1);



			svg {
				fill: rgba(var(--theme-color-red), 1);

				#{$self}--playing & {
					//.pause {
					//	display: block;
					//}

					.play {
						display: none;
					}
				}
			}

			&:hover {
				border-color: rgba(var(--theme-color-red), 1);
				background: rgba(var(--theme-color-red), 1);
				svg {
					fill: rgba(var(--theme-color-white), 1);
				}
			}
		}
	}

	VIDEO {
		width: 100%;
		height: auto;
	}
}