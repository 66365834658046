.pagination {
	$self: &;

	--item-width: 2rem;
	--item-height: 2rem;


	padding:0;
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;

	&--default {}

	&--button {
		--item-height: calc(var(--layout-button-size));
	}

	&--title {
		--item-height: calc(var(--layout-button-size) - 0.4rem);

		display: inline-flex;
		height: var(--layout-button-size);
		border-radius: var(--layout-button-size);
		border: 0.2rem solid rgba(var(--theme-color-aubergine), 1);
	}

	&--tabs {
		justify-content: flex-start;
	}

	&--icons {
		justify-content: space-around;
		margin:0;
	}

	&--dots {
		display: none;
	}


	&__item {
		@include font-style-pagination;
		transition: all $tt-std ease;
		display: block;
		cursor: pointer;

		#{$self}--small & {
			--item-width: 1.2rem;
			--item-height: 1.2rem;
			margin-right: 1rem;
		}


		#{$self}--default & {
			transform-origin: center;
			width: var(--item-width);
			height: var(--item-height);
			margin-right: 2rem;
			border-radius: var(--item-height);
			background: rgba(var(--bgca, var(--theme-color-aubergine)), 0.45);

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background: rgba(var(--bgca, var(--theme-color-aubergine)), 1);

			}

			&--active {
				--item-width: 5rem;
				box-shadow: 0px 0px 2rem rgba(var(--theme-color-grey), 0.3);
			}
		}

		#{$self}--icons & {
			--item-width: 4rem;
			--item-height: var(--item-width);
			--item-bg: var(--bgca, var(--theme-color-aubergine));
			--item-scale: 0.2;

			@include respond-to(tablet) {
				--item-width: 5rem;
			}
			@include respond-to(display) {
				--item-width: 8rem;
			}

			position: relative;
			width: var(--item-width);
			height: var(--item-height);
			border-radius: 100%;
			background: rgba(var(--item-bg), 1);
			opacity: 0.4;


			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 60%;
				height: 60%;
				//fill: rgba(var(--theme-color-white-real),1);
			}

			&:before {
				transition: transform $tt-std ease, opacity $tt-std ease;
				transform: translate(-50%, -50%) scale(var(--item-scale));
				content: ' ';
				position: absolute;
				top:50%;
				left:50%;
				z-index: -1;
				opacity: 0;
				pointer-events: none;

				background: transparent;
				border:0.2rem solid rgba(var(--item-bg), 1);

				width: calc(var(--item-width) + 1rem);
				height: calc(var(--item-height) + 1rem);
				border-radius: 100%;
			}

			&:hover {
				--item-scale: 1;
				opacity: 0.75;
				&:before {
					opacity: 0.3;
				}
			}

			&--active {
				opacity: 1;
				&:hover {
					opacity: 1;
				}
			}
		}

		#{$self}--button &,
		#{$self}--title & {
			--pseudo-opacity: 0;
			--pseudo-opacity-border: 0;
			--pseudo-width: var(--item-height);

			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			padding: 0 2.5rem;
			height: var(--item-height);

			&:after {
				transition: all $tt-std ease;
				transform: translateX(-50%) translateY(-50%);
				position: absolute;
				top:50%;
				left: 50%;
				z-index: -1;

				display: block;
				content: ' ';
				width: var(--pseudo-width);
				height: var(--item-height);

				background: rgba(var(--theme-color-aubergine), var(--pseudo-opacity));
				border: 0.2rem solid rgba(var(--theme-color-aubergine), var(--pseudo-opacity-border));
				border-radius: var(--item-height);
			}

			&:hover {
				--pseudo-width: calc(100% - 0.2rem);
				--pseudo-opacity-border: 1;
			}
			&--active:hover,
			&--active {
				--pseudo-opacity: 1;
				--pseudo-opacity-border: 1;
				--pseudo-width: calc(100% - 0.4rem);
				color: rgba(var(--theme-color-white), 1);
			}
		}

		#{$self}--button & {
			--pseudo-width: 100%;
			margin: 0 1rem;
			&:hover {
				--pseudo-width: 100%;
			}
		}
	}
}