.accordion {
	$self: &;
	$item: #{$self}__item;

	--border-color: rgba(var(--theme-color-grey-2), 1);
	--font-color: rgba(var(--fc, var(--theme-color-font-std)), 1);
	--icon-color: var(--border-color);
	--icon-rotation: 0deg;

	&--dark {
		--border-color: rgba(var(--theme-color-grey-2), 1);
	}

	&__item,
	&__header,
	&__content {
		width: 100%;
	}

	&__item {
		transition: all $tt-std ease;
		border-top: 0.2rem solid var(--border-color);
		border-bottom: 0.2rem solid var(--border-color);

		& + & {
			border-top: 0;
		}

		&--open + &--open {
			border-top-color: rgba(var(--theme-color-white-real),1);
		}

		&--open {
			--icon-rotation: 180deg;
		}
	}

	&__header {
		@include font-style-body-lead;
		transition: all $tt-std ease;
		display: flex;
		align-items: center;
		height: 10.5rem;
		padding: 0 2rem;
		cursor: pointer;
		background: transparent;


		&:hover,
		#{$item}--open & {
			background: var(--border-color);
			--font-color: rgba(var(--theme-color-white-real) ,1);
			--icon-color: var(--font-color);
		}

	}

	&__title {
		color: var(--font-color);
	}

	&__icon {
		margin-left: auto;

		svg {
			transform: rotate(var(--icon-rotation));
			fill: var(--icon-color);
			width: auto;
			height: 2.7rem;
		}
	}

	&__content {
		display: block;

		.area {

			padding: 3rem 0;
		}

		.layout--editmode & {
			height: auto;
		}

		.layout--livemode & {
			overflow: hidden;
			display: none;
		}
	}
}