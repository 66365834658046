.pricingcompare {
	$self: &;

	--border-color: var(--theme-color-green-grey);
	--font-color: var(--theme-color-font-std);

	@extend %container;
	@extend %grid;

	display: grid;

	@include respond-to(tablet) {
		margin-top: var(--layout-section-spacing);
		margin-bottom: calc(var(--layout-section-spacing) * 2);
	}

	&__control {
		display: flex;
		justify-content: center;

		grid-column: span 12;

		@include respond-to(tablet) {
			display: none;
		}
	}

	&__compare {

		grid-column: span 12;
		margin-bottom: 5rem;

		@include respond-to(tablet_landscape) {
			grid-column: span 9;
		}

	}

	&__savings {

		height: 100%;
		grid-column: span 12;
		@include respond-to(tablet_landscape) {
			grid-column: span 3;
			padding-left: var(--layout-gutter);
		}




	}

	&__saving-box {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 4rem;
		text-align: center;

		@include respond-to(tablet_landscape) {
			padding: 0 4rem;
			text-align: left;
		}


		background: rgba(var(--theme-color-green), 1);
		color: rgba(var(--theme-color-white-real), 1);
	}

	&__saving-text,
	&__saving-value {
		width: 100%;
	}

	&__saving-value {
		margin-top: 1rem;
		@include respond-to(tablet_landscape) {
			margin-top: 2.2rem;
		}
	}





	&__table-row {
		display: grid;

		grid-template-columns: 50% 50%;
		grid-template-areas: 'label col-1';
		@include respond-to(tablet) {
			grid-template-columns: 32% 34% 34%;
			grid-template-areas: 'label col-1 col-2';
		}

		grid-template-rows: 8rem;

		padding-bottom: 2rem;

		border-bottom: 0.1rem solid rgba(var(--border-color), 1);

		&--summary {
			border-bottom: 0;
			margin-bottom: 1.6rem;
			box-shadow:
				0 0.05rem 0 0 rgba(var(--border-color), 1),
				0 0.6rem 0 0 #fff,
				0 0.75rem 0 0 rgba(var(--border-color), 1),
				0 1.2rem 0 0 #fff,
				0 1.35rem 0 0 rgba(var(--border-color), 1);
		}

		&--last {
			padding-top: 2rem;
			border-bottom: 0;
			grid-template-rows: auto;
		}
	}

	&__table-col {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		padding-right: var(--layout-gutter);

		.layout--livemode & {
			//user-select: none;
		}

		&--head {
			@include respond-to(until_tablet) {
				grid-column: span 12;
				BR {
					display: none;
				}
			}

			@include respond-to(tablet) {

			}
		}

		&--center,
		&--right {
			display: flex;
			justify-content: flex-end;
			text-align: right;
		}

		&--right {

			--font-color: var(--theme-color-green);
			color: rgba(var(--font-color) ,1);
		}
	}

	&__field {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		width: 100%;
		line-height: 1.3;

		#{$self}__label {
			padding-right: 1rem;
			@include respond-to(tablet) {
				padding-right: 10rem;
			}

		}

		&--big {
			@include respond-to(until_tablet) {
				@include font-size-h3;
				text-align: left;
			}

			justify-content: flex-start;
		}

		&--table-desc {
			justify-content: flex-start;
		}

		&--toplabel {
			flex-direction: column;
			#{$self}__label {
				@include font-style-body-small;
				padding-right: 0;
			}
		}
	}

	&__label,
	&__value {
		display: block;
		color: rgba(var(--font-color), 1);
	}

	&__label {
		//@include font-style-body-small;
	}

	&__saving-value,
	&__value {
		@include font-style-pricingcompare-value;
		&--summary {
			@include font-style-headline-1;
		}
	}
}