.form {
	$self: &;
	$formgroup: '.form__group';

	--input-height: var(--layout-input-height, 5rem);
	--form-group-spacebetween: 1.8rem;
	--form-input-background: rgba(var(--theme-color-white-real), 1);
	--form-input-border: rgba(var(--theme-color-grey), 1);

	--form-select-border: var(--theme-color-grey);
	--form-checkbox-radio-background: var(--form-input-background);
	--form-checkbox-radio-border: var(--form-input-background);

	&--newsletter {
		--input-height: var(--layout-button-size);
	}

	&--calculator {

	}

	&--career {
		--form-input-background: rgba(var(--theme-color-green-light), 1);
		--form-input-border: rgba(var(--theme-color-white-real), 1);
		--form-select-border: var(--theme-color-white-real);
		--form-select-icon: var(--theme-color-black);

		--form-checkbox-radio-background: rgba(var(--theme-color-white-real) ,1);
		--form-checkbox-radio-border: rgba(var(--theme-color-white-real) ,1);


		.selectr-selected {
			border-top:0 !important;
			border-left:0 !important;
			border-right: 0 !important;
		}
	}

	&--calculator-hfp {
		width: 100%;
	}

	&__group {
		&--nvc {
			visibility: hidden;
			display: none;
		}
	}

	&__buttons {
		margin: 3rem 0;

		&--nm {
			margin:0;
		}
	}

	A {
		color: rgba(var(--theme-color-red), 1);
		&:hover {
			color: rgba(var(--theme-color-red-2), 1);
		}
	}


	/** split to fields like zip / city **/
	&__splitter {

		& + & {
			margin-top: 0.2rem;
		}

		@include respond-to(tablet) {
			display: flex;
			justify-content: space-between;

			#{$formgroup} {
				width: 50%;

				&:first-child {
					margin-right: var(--form-group-spacebetween);
				}
			}

			&--left {
				#{$formgroup}:first-child {
					width: 20%;

					.tooltip { display: none!important; }
				}

				#{$formgroup}:last-child {
					width: calc(80% - var(--form-group-spacebetween));
				}
			}

			&--right {
				#{$formgroup}:first-child {
					width: 80%;
				}

				#{$formgroup}:last-child {
					width: calc(20% - var(--form-group-spacebetween));
					.tooltip { display: none!important; }
				}
			}

			&--right-wide {
				#{$formgroup}:first-child {
					width: 70%;
				}

				#{$formgroup}:last-child {
					width: calc(30% - var(--form-group-spacebetween));
					.tooltip { display: none!important; }
				}
			}
		}
	}

	&__group {
		position: relative;
		margin-bottom: var(--form-group-spacebetween);
		&:last-child {
			@include respond-to(tablet) {
				margin-bottom: 0;
			}
		}

		&--has-success {

		}

		&--has-error {

		}

		&--suffix {
			display: flex;
			align-items: center;
		}

		&--nm {
			margin: 0;
		}
	}

	&__group-suffix {
		display: flex;
		align-items: center;

		width: 30%;
		background: var(--form-input-background);
		height: var(--input-height);
		padding: 0 2rem 0 0;
		border: 0.2rem solid var(--form-input-border);
		border-left:0;

		@include respond-to(until_tablet) {
			font-size:0.8em;
		}

		@at-root #{$formgroup}--has-error & {
			border-color: rgba(var(--theme-color-validation-error), 1);

		}
	}

	&__section-label {
		//include font-style-body-small;
		//include font-reg;
		display: block;
		margin: 1.2rem 0 2rem;

		margin-top: 6rem;
		&:first-child {
			margin-top: 1.2rem;
		}
	}

	&__tooltip {
	}

	&__message {
		color: var(--color-white);
		background: var(--color-validation-success);
		margin-bottom: 1.8rem;
		padding: 0.5rem 0;
		text-align: center;

		&--error {
			background: var(--color-validation-error);
		}
	}

	&__input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&::placeholder {
			opacity: 0.5;
		}

		//&::-webkit-input-placeholder {
		//	line-height:normal!important;
		//}

		&__hint {
			@include font-style-body-small;
			padding-inline:2rem;
			margin-block-start: 0.5em;
			opacity: 0.8;

		}

		width: 100%;
		height: var(--input-height);
		padding: 0 2rem;
		border: 0.2rem solid var(--form-input-border);

		background: var(--form-input-background);

		@at-root #{$formgroup}--has-error & {
			border-color: rgba(var(--theme-color-validation-error), 1);
		}

		&:focus + .tooltip {
			opacity: 0;
		}

		&--nb {
			border-color: transparent;
		}

		#{$self}--newsletter & {
			color: rgba(var(--theme-color-white-real), 1);
			border-radius: var(--input-height);
			background: transparent;
		}

		#{$self}--newsletter-subscription & {
			//color: rgba(var(--theme-color-white-real), 1);
			border-radius: var(--input-height);
			background: transparent;
			border-color: rgba(var(--theme-color-aubergine), 1);
		}

		#{$self}--career & {
			border-top:0;
			border-left:0;
			border-right:0;
		}

		#{$self}__group--suffix & {
			width: 70%;
		}


		@at-root #{$formgroup}--has-error#{$formgroup}--suffix & {
			border-color: rgba(var(--theme-color-validation-error), 1);
			border-right: 0;

		}

		@at-root #{$formgroup}--suffix & {
			border-right: 0;
		}

		&:not(:placeholder-shown) + #{$self}__input-placeholder {
			opacity: 0.5;

		}

	}

	&__input-placeholder {
		transition: opacity $tt-std ease;
		position: absolute;
		top:1rem;
		right:1rem; //padding of input
		z-index: 10;
		font-size: 0.65em;
		pointer-events: none;
		user-select: none;
		opacity: 0.0;  //0.5 //same as ::placeholder
		color:rgba(var(--theme-color-font-std), 1);
	}

	&__icon {
		opacity: 0.5;
		width: 100%;
		height: 100%;

		svg {
			height: calc(var(--input-cta-height) - 2rem);
			height: 100%;
		}
	}

	&__radio,
	&__checkbox {
		display: none;


		//--element-color: var(--theme-color-aubergine);


		& + LABEL {

			display: inline-block;
			//display: flex;
			& + .tooltip {
				display: none;
			}

			cursor: pointer;
			//display: flex;
			//align-items: center;

			&:before {
				transition: all $tt-std ease;
				width: 3.5rem;
				height: 3.5rem;
				background: var(--form-checkbox-radio-background);

				border: 0.2rem solid var(--form-checkbox-radio-border);
				border-radius: 0.2rem;

				display: block;
				content: "";
				float: left;

				margin-right: 1.3rem;
				transform: translateY(-0.5rem);

				@at-root #{$formgroup}--has-error & {
					border: 0.2rem solid rgba(var(--theme-color-validation-error), 1);
					background: var(--form-checkbox-radio-background);
				}

			}

			A {
				text-decoration: underline;
			}

		}

		&--flex {
			& + LABEL {
				display: flex;
			}

			&:before {
				float: unset;

			}
		}

		&--small {
			& + LABEL {
				@include font-style-body-small;
				&:before {
					transform: translateY(-0.9rem);
				}

			}


		}

		&:checked + LABEL {
			&:before {
				background: rgba(var(--theme-color-aubergine), 1);
				border-color: rgba(var(--theme-color-aubergine), 1);
			}
		}

		&[disabled],
		&[disabled] + LABEL {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&__radio {
		& + LABEL {
			&:before {
				border-radius: 3.5rem;
			}
		}
	}



	TEXTAREA {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}

	.dropdown-instance {

		--element-color: var(--form-select-border);
		--icon-color: var(--form-select-icon);
		--border-color: var(--element-color);

		@at-root #{$formgroup}--has-error .dropdown-instance{
			--border-color: var(--theme-color-red);
		}

		.selectr-selected {

			display: flex;
			align-items: center;

			height: var(--input-height);
			padding: 0 2rem;
			border: 0.2rem solid rgba(var(--border-color), 1);
			border-radius: 0.2rem;
			background: var(--form-input-background);

			&:before {
				border-color: rgba(var(--icon-color), 1) transparent transparent;
			}
		}

		.selectr-input {
			border:0;
			background: rgba(var(--element-color), 1);
		}

		.selectr-options {
			max-height: 25rem;
		}

		.selectr-options-container {
			border: 0.2rem solid rgba(var(--border-color), 1);
			border-top-color: transparent;

		}

		.selectr-option {
			//include font-style-form;

			padding: 0.5rem 2rem;
			height: 3.6rem;

			&.active {
				color: inherit;
				background: rgba(var(--element-color), 0.2);
			}

			&.selected {
				background: rgba(var(--element-color), 0.5);
			}
		}
	}

	.form__upload {
		&-zone {

			display    : block;
			width      : 100%;
			margin     : 2rem auto 4rem auto;
			padding    : 6rem 2.5rem;
			background : rgba(var(--form-input-background), 1);
			border     : 0.2rem solid rgba(var(--theme-color-black), 1);
			text-align : center;
			cursor     : pointer;
			transition : box-shadow $tt-std, border-color $tt-std, color $tt-std;

			.text {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.icon {
				width: 4rem;
				height: 4rem;
				fill: currentColor;
				margin-right: 2rem;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			&.dragged {
				border-color : rgba(var(--theme-color-black), 1);
				color        : rgba(var(--theme-color-black), 1);
			}
		}

		.upload-file-row {
			border: 0.2rem solid rgba(var(--theme-color-black), 1);
			padding: 1.6rem;
			display: flex;
			align-items: center;
			margin-bottom:2rem;

			.left,
			.right {
				width: 10%;
			}

			.center {
				width: 80%;
				text-align: left;
			}

			.right {
				text-align: right;
			}

			.left svg {
				width: 3.2rem;
				height: 4.2rem;
				fill: var(--color-mint);
			}

			.center {
				SPAN {
					display: block;
					width:100%;
				}

				.filename {
					@include font-style-body-small;
					line-height: 1;
				}

				.filesize {
					@include font-size-body-small;
				}
			}

			button {
				background: transparent;
				border:0;
				cursor: pointer;

				svg {
					width: 2rem;
					height: 2rem;
					fill: var(--color-mint);
				}
			}

			.dz-remove {
				display: none;
			}
		}
	}

}



/**
 * Example:
 * <div class="password password--hidden">
 * 	<input type="password" name="userPassword" class="password__input form__input" placeholder="<?= $this->translate('form.access-now.placeholder.password'); ?>" required>
 * 	<div class="password__icons">
 * 		<div class="password__icon password__icon--show">
 * 			<?= $this->svg()->getInline('eye-outline'); ?>
 * 		</div>
 * 		<div class="password__icon password__icon--hide">
 * 			<?= $this->svg()->getInline('eye-off-outline'); ?>
 * 		</div>
 * 	</div>
 * 	<div class="password__strength">
 * 		<div class="password__strength-line"></div>
 * 		<div class="password__strength-line"></div>
 * 		<div class="password__strength-line"></div>
 * 	</div>
 * </div>
 */

.password {
	$self: &;

	--icon-color: var(--theme-color-aubergine);
	--padding-right: 6rem;

	position: relative;

	//password value is hidden
	&--hidden {

	}

	&__container {
		position: relative;
		&:first-child {
			margin-bottom: var(--form-group-spacebetween);
		}

		&--hint {
			@include font-style-body-small;
			opacity: 0.5;
			padding-left: 2.1rem;
			@include respond-to(tablet) {
				width: 75%;
			}
		}
	}

	&__input {
		padding-right: var(--padding-right);

	}

	&__input-repeat {

	}

	&__icons {
		transform: translateY(-50%);
		width: var(--padding-right);
		position: absolute;
		right: 0;
		top: 50%;
		cursor: pointer;

		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			--icon-color: var(--theme-color-red);
		}
	}

	&__icon {
		width: 4rem;
		height: auto;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 80%;
			height: auto;
			fill: rgba(var(--icon-color), 0.5);
		}

		&--show {
			display: none;
			#{$self}--hidden & {
				display: flex;
			}
		}
		&--hide {
			display: flex;
			#{$self}--hidden & {
				display: none;
			}
		}
	}

	&__conditions {
		@include font-style-body-small;
		padding: 0 2rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		list-style-type: none;

	}

	&__condition {
		transition: all $tt-std ease;
		position: relative;

		&:before {
			content: "";
			width: 0%;
			height: 2px;
			background: rgba(var(--theme-color-green), 1);
			position: absolute;
			left: 0;
			top: 50%;
			display: block;
			transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		}

		&--valid {
			color: rgba(var(--theme-color-font-std), 0.3);
			&:before {
				width: 100%;
			}
		}
	}

	&__check,
	&__strength {
		height: 0.3rem;
		width: calc(100% - 1.2rem);

		position: absolute;
		//input border spacing
		bottom:0.6rem;
		left:0.6rem;

		display: flex;
		justify-content: space-between;
		align-items: center;

		&--failed,
		&--weak {
			#{$self}__check-line,
			#{$self}__strength-line {
				background: transparent;
				&:nth-child(1) {
					background: rgba(var(--theme-color-red), 1);
				}
			}
		}

		&--medium {
			#{$self}__strength-line {
				background: transparent;
				&:nth-child(1),
				&:nth-child(2) {
					background: rgba(var(--theme-color-yellow), 1);
				}
			}
		}

		&--passed,
		&--strong {
			#{$self}__check-line,
			#{$self}__strength-line {
				background: rgba(var(--theme-color-green), 1);
			}
		}
	}

	&__check-line,
	&__strength-line {
		width: 33%;
		height: 100%;

		background: transparent;
	}

	&__check-line {
		width: 100%;
	}
}


