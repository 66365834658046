.sharebar {
	$self: &;

	background: rgba(var(--theme-color-green-light), 1);

	&__container {
		@extend %container;
		@extend %grid;

		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	&__share {
		grid-column: span 12;
		@include respond-to(tablet) {
			grid-column: 1 / 4;
		}
	}

	&__pagination,
	&__tools {
		display: none;
		@include respond-to(tablet) {
			display: blocK;
		}
	}

	&__pagination {
		grid-column: 4 / 10;
	}

	&__tools {
		grid-column: 10 / 13;
	}
}