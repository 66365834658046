.footer {

	@include font-style-footer;

	$breakpointSwitch: tablet_landscape;
	$self: &;
	--grid-gutter: var(--layout-gutter);
	@include respond-to(display) {
		position: sticky;
	}

	.browser--safari &,
	.browser--edge & {
		position: initial;
	}

	display: grid;

	bottom: 0;
	left: 0;
	width: 100%;

	background: rgba(var(--theme-color-footer), 1);
	color: rgba(var(--theme-color-font-dark), 1);


	@include respond-to($breakpointSwitch) {
		min-height: 50rem;
	}
	
	&__container {
		align-self: end;
		justify-self: center;

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 8rem auto;
		grid-template-areas:
			'app'
			'bottom'
		;
		@include respond-to($breakpointSwitch) {
			grid-template-rows: 2rem auto 8rem 9rem;
			grid-template-areas:
				' . '
				'top'
				'app'
				'bottom'
			;
		}


		height: 100%;
		width: 100%;
	}

	&__navigation,
	&__service {
		@extend %container;
		display: grid;

		padding: 2rem;
		grid-template-columns: var(--grid-gutter) auto var(--grid-gutter);
		grid-template-rows: repeat(3, auto);
		grid-template-areas:
			'. social .'
			'. meta .'
			'. copyright .'
		;
		grid-row-gap: 3rem;

		@include respond-to($breakpointSwitch) {
			grid-template-columns: repeat(12, 1fr);
			grid-template-rows: none;
			grid-template-areas: none;
			grid-column-gap: var(--grid-gutter);
			//grid-row-gap: initial;
		}
	}

	&__navigation {
		grid-area: top;
		display: none;
		@include respond-to($breakpointSwitch) {
			display: inherit;
		}
	}

	&__service {
		grid-area: bottom;
		border-top: 0.2rem solid rgba(var(--theme-color-green-2), 1);
	}

	&__app {
		@extend %container;
		grid-area: app;

		background: rgba(var(--theme-color-green-light), 1);

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas: 'apps';
		@include respond-to($breakpointSwitch) {
			grid-template-columns: 0.25fr 0.75fr 0.25fr;
			grid-template-areas: 'label apps right';
		}
	}

	&__app-label,
	&__app-apps,
	&__app-right {
		display: flex;
		align-items: center;
	}

	&__app-label,
	&__app-right {
		display: none;
		@include respond-to($breakpointSwitch) {
			display: inherit;
		}

	}

	&__app-label {
		grid-area: label;
	}
	&__app-apps {
		grid-area: apps;
		justify-content: center;

		IMG, PICTURE {
			height: 6rem;
			width: auto;
		}
	}
	&__app-right {
		grid-area: right;
	}


	&__copy,
	&__service-nav,
	&__social {
		grid-column: span 12;
		justify-self: center;

		@include respond-to($breakpointSwitch) {
			align-self: center;
			justify-self: initial;
		}
	}

	&__copy {
		grid-area: copyright;


		@include respond-to($breakpointSwitch) {
			grid-area: auto;
			grid-column: span 3;
		}


	}
	&__service-nav {
		grid-area: meta;
		justify-self: initial;

		@include respond-to($breakpointSwitch) {
			grid-area: auto;
			grid-column: span 5;
		}
	}
	&__social {
		grid-area: social;
		@include respond-to($breakpointSwitch) {
			grid-area: auto;
			grid-column: span 4;
			justify-self: end;
		}

		.button {
			--o-button-color: var(--theme-color-grey-2);
			&:hover {
				--o-button-color: var(--theme-color-green-2);
			}
			margin: 0 0.5rem;
			&:last-child {
				margin-right: 0;
			}
		}


	}

}