.cta {
	$self: &;

	--o-button-font-color: var(--theme-color-red);
	--o-button-font-color-hover: var(--theme-color-red-2);
	--o-button-color: var(--theme-color-white-real);

	@extend %container;
	display: flex;

	height: 18rem;

	background: rgba(var(--theme-color-red), 1);
	background-size: cover;
	//background-image: url(/static/images/svg/cta-banner.svg);
	background-image: url(/static/images/cta-banner.png);
	//background-image: url(../../images/cta-banner.png);

	background-repeat: no-repeat;

	&__container {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;

		padding: 0 3rem;

		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		@include respond-to(tablet) {
			flex-wrap: nowrap;
			text-align: left;
			justify-content: flex-start;
		}

		@include respond-to($layout-default-vertical-layout-breakpoint) {
			padding: 0 6rem;
		}
	}

	&__icon {
		display: none;
		@include respond-to($layout-default-vertical-layout-breakpoint) {
			display: block;
		}
	}
	&__text{
		display: block;
	}

	&__cta {
		@include respond-to(tablet) {
			margin-left: auto;
		}
	}

	&__text {
		@include font-style-cta;
		color: rgba(var(--theme-color-white-real), 1);
	}

	&__icon {
		width: 6.5rem;

		margin: 0 3rem 0 0;
		@include respond-to($layout-default-vertical-layout-breakpoint) {
			margin: 0 6rem 0 0;
		}

		svg {
			fill: rgba(var(--theme-color-white-real), 1);
		}
	}
}