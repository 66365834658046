.newsletter,
.newsletter-prevent-hs {
	$self: &;


	background: rgba(var(--theme-color-green-2), 1);
	//background-image: url('/static/images/svg/newsletter-background.svg');
	//background-size: cover;

	background-repeat: no-repeat;

	color: rgba(var(--theme-color-white), 1);
	//padding: 8rem 0 10rem;
	padding: 2.3rem 0;
	position: relative;
	z-index: $z-newsletter;





	&__container {
		@extend %container;
		@extend %grid;

		//display: none !important;
	}

	&__content,
	&__cta {
		grid-column: span 12;

		@include respond-to(until_tablet) {
			align-self: center;
			justify-self: center;
		}
	}

	&__content {
		--pc: var(--theme-color-white);

		@include respond-to(tablet) {
			grid-column: 1 / span 5;

		}
	}

	&__cta {

		@include respond-to(tablet) {
			grid-column: 6 / 13;
			display: flex;
			align-items: center;
		}
		width: 100%;
		align-self: end;

		.button {
			margin-top: 2rem;
			@include respond-to(tablet) {
				margin-top:0;
				margin-left: 2rem;
			}
		}

		.form__group {
			width: 100%;
		}
	}
}

.newsletter-subscription {
	margin-bottom: 0;
	padding-bottom: 0 !important;

	&__grid {
		background: linear-gradient(0deg, rgba(246,233,226,1) 0%, rgba(229,237,238,1) 100%);

		padding: calc(var(--layout-section-spacing) * 2) 0;

		@include respond-to(display) {
			padding: calc(var(--layout-section-spacing) * 3) 0;
		}
	}

	&__email {
		@include respond-to(tablet) {
			margin: 8rem 0 4rem;
		}
	}

	&__form-group {
		@include respond-to(tablet) {
			margin-bottom: 4rem;
		}
	}
}

.newsletter-subscription-banner {

	@include respond-to(tablet) {
		min-height: 8.3rem;
	}


	a {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		padding: 0 var(--layout-page-gutter);
		text-align: left;

		& > div {
			display: flex;
			align-items: center;

		}

		.button {
			margin-top: 2rem;
		}

		@include respond-to(tablet) {
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding:0;
			text-align: center;

			.button {
				margin-top: 0;
			}
		}

	}

	svg {
		height: 4rem;
		width: 4rem;
		fill: rgba(var(--theme-color-white), 1);
		margin-right: 2rem;
	}
}