.features {
	$self: &;

	@extend %container;

	&__controls {
		width: 100%;

		margin: 4rem 0 8rem;
		@include respond-to($layout-default-vertical-layout-breakpoint) {
			margin: 8rem 0 12rem;
		}
	}

	&__list {
		.layout--livemode & {
			display: grid;
			grid-template-rows: 1fr;
			grid-template-columns: auto;
			margin-bottom: 4rem;
		}
	}

	&__item {
		@extend %grid;

		.layout--livemode & {
			transition: all $tt-std ease;
			grid-column: 1;
			grid-row: 1;

			display: none;
			visibility: hidden;
			opacity: 0;

			&--active {
				display: grid;
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__text,
	&__media {
		grid-column: span 12;
		//align-self: center;
	}

	&__text {
		@include respond-to(tablet) {
			grid-column: 1 / 7;
		}
	}

	&__media {
		@include respond-to(tablet) {
			grid-column: 8 / 13;
		}
	}
}