.side-banner {
	$self: &;
	--margin-left: 0;

	display: none;

	&--login {
		--margin-left: 1.2rem;
	}

	&__overlay {
		position: fixed;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: rgba(var(--theme-color-grey), 0.0);
		z-index: $z-sidebanner-overlay;

		opacity: 0;
		//opacity: 0;
	}

	&__wrapper {
		position: fixed;
		transform: translateY(-50%) translateX(100%);
		position: fixed;
		top: 50%;
		right: 0;
		z-index: $z-sidebanner;
	}

	&__close {
		text-align: right;
		cursor: pointer;
		padding-right: 1.5rem;

		svg {
			--icon-color: var(--theme-color-grey-2);
			border: 0.2rem solid rgba(var(--icon-color), 1);
			border-radius: 3rem;
			padding: 0.5rem;

			width: 3rem;
			height: 3rem;
			transform: rotate(-90deg);
			fill: rgba(var(--icon-color), 1);

			&:hover {
				--icon-color: var(--theme-color-red);
			}
		}
	}

	&__top,
	&__bottom {
		//@include font-style-body-small;
		@include font-style-body;

		width: 27rem;
		padding: 2rem 1.5rem;
		border-radius: var(--layout-border-radius);
		background: rgba(var(--theme-color-white-real), 1);

		#{$self}--login & {
			padding-left: calc(2rem - var(--margin-left));
			P {
				margin-top: 0;
				margin-left: var(--margin-left);
			}
		}
	}

	&__top {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: 0.2rem;

		#{$self}--login & {
			.button {
				margin-left: var(--margin-left);
			}
		}

	}

	&__bottom {
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		#{$self}--login & {

			P {
				margin-bottom:0;
			}

			IMG {
				width: 75%;
			}

			A {
				display: block;
				text-align: left;
			}
		}
	}
}