/* old
.access-now {
	$self: &;

	//display: none;

	//overflow-x: hidden;
	//overflow-y: auto;
	padding-top: 3rem;
	height: 100%;

	&__container {
		//extend %container;
		@extend %grid;
		grid-template-rows: auto auto;

		grid-template-areas:
			'form form form form form form form form form form form form'
			'cont cont cont cont cont cont cont cont cont cont cont cont'
		;

		@include respond-to(tablet) {
			grid-template-areas:
				'form form form form form form form form form form form form'
				'. .    .    cont cont cont cont cont cont .    .    . '
			;
		}

		@include respond-to(tablet_landscape) {
			grid-template-areas:
				'cont cont cont cont . form form form form form form .'
				'cont cont cont cont . form form form form form form .'
			;
		}

		@include respond-to(display) {
			grid-template-areas:
				'. cont cont cont . form form form form form form .'
				'. cont cont cont . form form form form form form .'
			;
		}

		height: 100%;

		//opacity: 0;


		//@include respond-to(tablet_landscape) {
		//	grid-template-rows: calc(100vh - var(--layout-header-height));
		//}
	}

	&__content,
	&__form {
		--pc: var(--theme-color-red);
	}

	&__title {
		margin-bottom: 3rem;
	}

	&__content {
		grid-area: cont;

		@include respond-to(tablet_landscape) {
			margin-top: 9rem;
		}

		@include respond-to(display) {
			margin-top: 3.2rem;
		}



		//@include respond-to(tablet_landscape) {
		//	grid-column: 1 / 7;
		//}
		//@include respond-to(display) {
		//	margin-top: 9rem;
		//	grid-column: 1 / 4;
		//}
	}

	&__form {
		grid-area: form;

		//@include respond-to(tablet_landscape) {
		//	//padding-top: 9rem;
		//	grid-column: 7 / 13;
		//}
		//@include respond-to(display) {
		//	//padding-top: 12rem;
		//	grid-column: 5 / 13;
		//}

	}

	&__close {
		position: absolute;
		top:0;
		right: var(--layout-gutter);
		width: 4rem;
		height: 4rem;

	}
}*/

.acn {
	$self: &;

	padding-top: calc(var(--layout-header-height) + 2rem);

	&__background {
		display: none;
		background: rgba(235,232,234, 1);
		opacity: 1;
		position: absolute;
		top:0;
		right:0;
		width: 0;
		height: 100%;
		pointer-events: none;

		@include respond-to(tablet) {
			display: block;
		}

		#{$self}--demo-request & {
			opacity: 0.5;
		}
	}

	&__container {
		@extend %container;
		@extend %grid;

		height: auto;

		@include respond-to(tablet) {
			height: calc(100vh + 30rem);
		}

		.layout--editmode & {
			height: auto;
		}
	}

	FORM,
	&__left,
	&__right {
		@include respond-to(tablet) {
			height: 100%;
		}
	}


	&__left {
		grid-column: 1 / 13;
		display: none;

		@include respond-to(tablet) {
			grid-column: 1 / 7;
		}
		@include respond-to(tablet_landscape) {
			grid-column: 1 / 6;
		}
		@include respond-to(display) {
			display: block;
			grid-column: 1 / 5;
		}
	}

	&__right {
		position: relative;

		grid-column: 1 / 13;

		@include respond-to(tablet) {
			//grid-column: 7 / 13;
		}
		@include respond-to(tablet_landscape) {
			//grid-column: 6 / 12;
		}
		@include respond-to(display) {
			grid-column: 5 / 11;
		}

		#{$self}--request-demo & {
			@include respond-to(tablet) {
				padding-top: 8.5rem;
			}
			@include respond-to(tablet_landscape) {
				padding-top: 5.8rem;
			}
			@include respond-to(display) {
				padding-top: 6.8rem;
			}
		}


		z-index: $z-xhr-container;
		opacity: 0;

		&:after {
			content: '';
			position: absolute;
			background: transparent;
			left:0;
			top:0;
			bottom:0;
		}
	}

	&__view {
		width: 100%;

		pointer-events: none;
		opacity: 0;
		visibility: hidden;

		#{$self}--access-now & {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.layout--editmode & {
			display: none;
			height: auto;
		}

		&--active {
			pointer-events: all;
			opacity: 1;
			visibility: visible;

			.layout--editmode & {
				position: static;
				display: block;
			}
		}

		&--progress,
		&--success,
		&--error {
			.layout--editmode & {
				pointer-events: all;
				display: block;
				position: static;
				opacity: 1;
				visibility: visible;
			}
		}


	}

	&__intro {
		padding-right: 0;
		@include respond-to(tablet) {
			padding-right: 3rem;
		}
		@include respond-to(tablet_landscape) {
			padding-right: 5rem;
		}

	}

	&__contact {
		.button {
			margin-bottom: 2rem;
		}
	}


	&__view-content {
		margin: 2rem 0 2rem;
		@include respond-to(tablet) {
			width: 75%;
		}
	}

	&__headline {
		margin-top: 3rem;
		margin-bottom: 3rem;

		@include respond-to(tablet_landscape) {
			margin-top: 5rem;
		}
		@include respond-to(display) {
			margin-top: 6rem;
		}
	}


	&__steps {
		margin:5rem 0 2rem;
		padding:0;
		list-style-type: none;

	}

	&__step,
	&__step-indicator,
	&__step-label {
		transition: all $tt-std ease;
	}


	&__step {
		--step-color: var(--theme-color-grey-2);
		opacity: 0.6;

		display: flex;
		align-items: center;
		padding-bottom: 3.5rem;

		pointer-events: none;
		cursor: default;

		&--active {
			--step-color: var(--theme-color-aubergine);
			opacity: 1;
			pointer-events: all;
			cursor: pointer;
			color: rgba(var(--step-color), 1);

			&:hover {
				--step-color: var(--theme-color-red);
			}
		}

		&--selected {
			--step-color: var(--theme-color-red);
		}

		&:last-child #{$self}__step-indicator:after {
			display: none;
		}
	}

	&__step-indicator,
	&__step-label {
		color: inherit;
	}

	&__step-indicator {
		position: relative;

		border: 0.2rem solid rgba(var(--step-color), 1);
		border-radius: 100%;

		width: 5rem;
		height: 5rem;

		display: flex;
		align-items: center;
		justify-content: center;

		margin: 0 2.5rem;

		&:after {
			content: '';
			position: absolute;
			top:calc(100% + 1rem);
			left:50%;
			width: 0.2rem;
			height: 2rem;
			background: rgba(var(--theme-color-grey-2), 1);
		}
	}


	&__apps {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 6rem;

		IMG, PICTURE {
			height: 6rem;
		}

	}
	&--request-demo{
		.acn__left {
			grid-column: 1 / 13;
			display: block;
			@include respond-to(tablet) {
				grid-column: 1 / 7;
			}
			@include respond-to(tablet_landscape) {
				grid-column: 1 / 7;
			}
			@include respond-to(display) {
				display: block;
				grid-column: 1 / 7;
			}
		}
	
		.acn__right {
			position: relative;
			grid-column: 1 / 13;
	
			@include respond-to(tablet) {
				grid-column: 7 / 13;
			}
			@include respond-to(tablet_landscape) {
				grid-column: 7 / 13;
			}
			@include respond-to(display) {
				grid-column: 7 / 13;
			}
		}
	}
}