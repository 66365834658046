.calculator {
	$self: &;


	&__inner {
		@extend %container;
		@extend %grid;

		grid-row-gap: 0;
		grid-template-rows: auto auto;


	}


	&__main,
	&__summary {
		grid-row: 1;
		grid-column: span 12;

		width: 100%;
		height: auto;

		overflow: hidden;

		display: grid;

		grid-template-rows: auto auto;
		grid-template-columns: 100%;
		grid-template-areas:
			'top'
			'bottom'
		;
		@include respond-to(tablet) {
			height: 80vh;
			grid-template-rows: 100%;
			grid-template-columns: 50% 50%;
			grid-template-areas:
				'left right'
			;

			height: 75rem;
		}

		@include respond-to(display) {
			height: 95rem;
		}

	}

	&__summary {
		grid-row: 2;
	}

	&__view {
		--tx: 0;
		position: relative;
		grid-area: bottom;

		@include respond-to(tablet) {
			grid-area: left;
			transform: translateX(var(--tx));
		}

		width: 100%;
		height: 100%;

		//flex-shrink: 0;

		&--calculator {
			--tx: 0;
			z-index: 20; //stay always on top

		}
		&--intro {
			--tx: 100%;
			z-index: 15;
			grid-area: top;
			background: rgba(var(--theme-color-calculator-green), 1);
			@include respond-to(tablet) {
				grid-area: left;
				background: rgba(var(--theme-color-calculator-blue), 1);
				color: rgba(var(--theme-color-white-real), 1);
			}
		}
		&--result {
			--tx: 200%;
			z-index: 10;
		}
		&--mail {
			--tx: 300%;
			z-index: 5;
		}

		&--hfp {
			--tx: 400%;
			z-index: 45;
		}
		&--hfp-right {
			--tx: 500%;
			z-index: 40;
		}


		&--calculator,
		&--intro,
		&--result,
		&--mail,
		&--hfp,
		&--hfp-right {
			padding: calc(var(--layout-gutter) * 2.5) calc(var(--layout-gutter) * 1.5);

			@include respond-to(tablet) {
				padding: 0 3rem;
			}
			@include respond-to(display) {
				padding: 0 12rem;
			}

			display: flex;
			align-items: center;
		}


		&--calculator,
		&--mail {
			background: rgba(var(--theme-color-calculator-blue), 1);
			color: rgba(var(--theme-color-white-real), 1);
		}

		&--mail {
		}

		&--result,
		&--hfp, {
			background: rgba(var(--theme-color-calculator-green-2), 1);
		}

		&--hfp {

		}

		&--hfp-right {
			background: rgba(var(--theme-color-calculator-blue), 1);
			color: rgba(var(--theme-color-white-real), 1);
		}
	}

	&__view-substep {
		width: 100%;
		&--hfpmail,
		&--mail {

		}

		&--hfpmail-sent,
		&--mail-sent {
			display: none;
			opacity: 0;
		}
	}

	&__summary {

		.layout--livemode & {
			opacity: 0;
		}

		grid-row: 2;
		height: auto;

		padding-top: 4rem;
		padding-bottom: 3rem;

		@include respond-to(tablet) {
			padding-top: 12rem;
			padding-bottom: 10rem;
		}

		background-color: rgba(var(--theme-color-white-real), 1);
		position: relative;

	}

	&__summary-result,
	&__summary-content {
		position: relative;
		z-index: 2;
	}

	&__summary-result {
		display: flex;
		align-items: center;
		justify-content: center;
		grid-area: top;
		padding-bottom: 4rem;
		@include respond-to(tablet) {
			padding-bottom: 0;
			grid-area: left;
		}


	}
	&__summary-content {
		--pc: var(--theme-color-red);
		grid-area: bottom;

		padding: calc(var(--layout-gutter) * 1.5);

		@include respond-to(tablet) {
			grid-area: right;
			padding: 0 3rem;
		}
		@include respond-to(display) {
			padding: 0 12rem;
		}
	}

	&__summary-background {
		position: absolute;
		z-index: 1;
		top:50%;
		left:0;
		transform: translateY(-50% ) rotate(180deg);
		width: 50%;
		opacity: 0.05;

		@include respond-to(display) {
			transform: rotate(180deg);
			top: 5rem;
			width: 45%;
		}
	}

	&__ball {
		@include font-style-headline-3;

		width: 30rem;
		height: 30rem;
		@include respond-to(display) {
			width: 48rem;
			height: 48rem;

		}

		border-radius: 100%;

		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		align-items: center;
		justify-content: center;

		background: rgba(var(--theme-color-red), 1);
		color: rgba(var(--theme-color-white-real), 1);
	}

	&__ball-logo {
		width: 10rem;
		height: auto;
		margin-bottom: 2rem;
		svg {
			fill: rgba(var(--theme-color-white-real), 1);
		}
	}

	&__ball-result {
		@include font-style-pricing;
	}
}

.calc {
	$self: &;
	--border-radius: 5rem;
	width: 100%;

	&__header,
	&__main {
		border: 0.2rem solid rgba(var(--theme-color-calculator-green), 1);
		padding: var(--border-radius) calc(var(--layout-gutter) * 1.5);

	}

	&__header {
		@include font-style-headline-2;
		@include font-light;

		padding-bottom: var(--layout-gutter);

		border-bottom: 0;
		border-top-left-radius: var(--border-radius);
		border-top-right-radius: var(--border-radius);
	}

	&__main {
		padding-top: calc(var(--layout-gutter) * 3.5);
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
	}


	//variables definied in buttons.scss
	.buttons--bar {
		--buttons-bar-background: rgba(var(--theme-color-calculator-green), 1);
		--buttons-bar-button-background:rgba(var(--theme-color-calculator-green), 1);
		--buttons-bar-button-color:rgba(var(--theme-color-white-real), 1);
		--buttons-bar-button-background-active:rgba(var(--theme-color-white-real), 1);
		--buttons-bar-button-color-active:rgba(var(--theme-color-calculator-green), 1);
	}

	.buttons--stretched {
		margin-top: 5rem;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
}

.results-table {
	$self: &;

	--background: rgba(0,0,0, 0);

	width: 100%;

	&__header,
	&__section {
		padding: var(--layout-gutter);
	}

	&__header {
		@include font-style-headline-2;
		@include font-light;

		padding-top: calc(var(--layout-gutter) * 2);
		color: rgba(var(--theme-color-white-real), 1);
		background: rgba(var(--theme-color-calculator-blue), 1);

	}

	&__section {
		background: var(--background);

		&--params {
			--background: rgba(var(--theme-color-calculator-green-3), 1);
			@include font-style-body-small;

		}

		&--costs,
		&--savings {
			padding-top: calc(var(--layout-gutter) * 2);
			padding-bottom: calc(var(--layout-gutter) * 2);
		}

		&--costs {
			--background: rgba(var(--theme-color-calculator-green), 1);

			[data-calculator-value="flc"] {
				margin-top:2rem;
			}
		}

		&--savings {
			@extend %font-bold;

			--background: rgba(var(--theme-color-calculator-blue), 1);
			color: rgba(var(--theme-color-red),1);

			[data-calculator-value="sav"] {
				margin-bottom:2rem;
			}
		}

		.wysiwyg {
			@include font-style-body-small;
		}
	}

	dl,dd,dt {
		margin:0;
		padding:0;
	}

	dl {
		position: relative;
		width: 100%;
		margin: 0.5rem 0;

		display: flex;
		justify-content: space-between;
		overflow: hidden;

		&:before {
			content: ' ........................................................................................................................... ';
			position: absolute;
			left:0;
			bottom:0;
			width: 100%;
			z-index:0;
		}

		&.no-dots {
			&:before {
				display: none;
			}
		}

	}

	dt, dd {
		position: relative;
		z-index: 1;

		padding:0;
		margin:0;
		background: var(--background);
	}

	dt {
		padding-right: 1rem;
	}

	dd {
		@extend %font-bold;
		padding-left: 1rem;
	}
}