.datatable {
	$self: &;

	&__search {
		margin: 2rem 0 2rem;
	}

	&__input {
		border:0;

	}

	&__header {
		background: rgba(var(--theme-color-grey), 1);
		color: rgba(var(--theme-color-white-real),1);
	}

	&__body {

	}

	&__header,
	&__body {

	}


	&__row {
		border: 2px solid rgba(var(--theme-color-grey), 1);
		display: flex;
		align-items: center;

		& + & {
			border-top:0;
		}

		&--hidden {
			display: none;

		}

	}

	&__more,
	&__column {
		width: 50%;
		height: var(--layout-input-height, 5rem);
		display: flex;
		align-items: center;
		padding: 0.8rem 2rem;
	}

	&__more {
		@include font-style-body-small;
		width: 100%;
		justify-content: center;
		text-align: center;
		cursor: pointer;

		display: none;

		&--visible {
			display: flex;;
		}
	}

}