.quote {
	$self: &;

	--color: rgba(var(--theme-color-red), 1);
	color: var(--color);

	&--wo-img {
		--color: rgba(var(--theme-color-green-2), 1);
	}

	&--extended {
		--color: rgba(var(--theme-color-white-real), 1);
	}

	//margin-bottom: var(--layout-grid-spacing);

	.flickity-slider & {
		margin-bottom: 0;
	}

	&__container {
		@extend %container;
		@extend %grid;
		//background: rgba(var(--theme-color-red-light), 1);
		background: transparent;
		//padding: var(--layout-section-bg-padding, initial);

		#{$self}--content-mode & {
			margin: auto;
			width: 100%;
			max-width: 100%;
			padding: 0;

			//grid-template-columns: 8rem auto;

			grid-template-columns: 8rem auto;
			grid-template-rows: auto auto;
		}

		#{$self}--extended & {
			grid-template-columns: auto;
		}

	}

	&__icon {


		text-align: right;
		grid-column: 2/5;
		@include respond-to(tablet) {
			text-align: left;
			grid-column: 2/3;
		}


		svg {
			width: 75%;
			fill: var(--color);
		}

		#{$self}--wo-img & {
			grid-column: 1/3;
			@include respond-to(tablet) {
				grid-column: 2/3;
			}
		}


		#{$self}--content-mode & {
			grid-column: 1;

			svg {
				width: 100%;
			}
		}

		#{$self}--content-mode#{$self}--small & {
			grid-row: 1;
		}

		#{$self}--extended & {
			display: none;
		}

	}

	&__from {
		grid-column: 5/12;
		@include respond-to(tablet) {
			grid-column: 3/5;
		}

		#{$self}--wo-img &,
		#{$self}--content-mode &,
		#{$self}--extended & {
			display: none;
		}


	}

	&__content {
		grid-column: 2/12;
		@include respond-to(tablet) {
			grid-column: 6/12;
		}

		#{$self}--wo-img & {
			grid-column: 3/13;
			@include respond-to(tablet) {
				grid-column: 3/11;
			}
		}

		#{$self}--extended & {
			grid-column: 2/12;
			@include respond-to(tablet) {
				grid-column: 2/10;
			}

			blockquote {
				@include font-style-quote-bold;
				margin:0;
				margin-block-end: 0.6em;
				text-wrap: balance;


				quotes: "“" "”" "‘" "’";
				text-indent: -0.45em;
				/* If there's support, erase the indent and use the property instead */
				@supports ( hanging-punctuation: first) {
					text-indent: 0;
					hanging-punctuation: first;
				}

				&:before {
					content: open-quote;
				}

				&:after {
					content: close-quote;
				}
			}
		}

		#{$self}--content-mode & {
			grid-column: 2;
		}

		#{$self}--content-mode#{$self}--small & {
			grid-column: 1 / 3;
			grid-row: 2;

		}
	}

	&__name {
		#{$self}--extended & {
			@extend %font-medium;
		}
	}


	&__position {
		#{$self}--extended & {
			@include font-style-body-small;
		}
	}
}