.sharebox {
	$self: &;

	//display: none;

	position: fixed;
	top:0;
	left:0;
	height: 100%;
	width: 100%;
	z-index: $z-sharebox;

	background: rgba(var(--theme-color-grey-3), 0.1);

	display: none;
	opacity: 0;
	//display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&__container {
		padding: 3rem 2rem 2rem;
		width: 90%;
		@include respond-to(tablet_landscape) {
			padding: 6rem 9rem 5rem;
			width: 45rem;
		}

		border-radius: var(--layout-border-radius);
		background: rgba(var(--theme-color-white) , 1);
		box-shadow: 0px 0.7rem 5rem -1.9rem rgba(0,0,0,0.4);

		A {
			color: inherit;
			&:hover {
				color: rgba(var(--theme-color-red), 1);
			}
		}
		//display: none;
	}

	&__title,
	&__privacy {
		text-align: center;
	}

	&__title {
		margin-bottom: 2.5rem;
	}

	&__items {
		margin: 2.5rem 0 4rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	&__privacy {
		@include font-style-body-small;
	}

	&__item {
		svg {
			transition: all 100ms ease-in-out;
			fill: rgba(var(--theme-color-red), 1);
		}

		&:hover {
			transform: scale(1.2);
			svg {
				fill: rgba(var(--theme-color-red-2), 1);
			}
		}
	}
}