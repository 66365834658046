/**
 * 100: Ultra Light
 * 200: Thin
 * 300: Light
 * 400: Regular
 * 500: Semi Bold
 * 600: Bold
 * 700: Extra Bold
 * 800: Heavy
 * 900: Ultra Heavy
 */

$font-weight-light:             300;
$font-weight-regular:           400;
$font-weight-medium:            500;
$font-weight-semi-bold:         600;
$font-weight-bold:              700;
$font-weight-extra-bold:        900;


$font-version: 0.1;
$font-path: '../../fonts/';
//$fonts: (
//	Realtext-0:             (name: 'Realtext-Light',                file: 'realtextpro/3A3B10_0_0',    font-weight: $font-weight-regular, font-style: normal), //light
//	Realtext-1:             (name: 'Realtext-LightItalic',          file: 'realtextpro/3A3B10_1_0',    font-weight: $font-weight-regular, font-style: normal), //light italic
//	Realtext-2:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_2_0',    font-weight: $font-weight-regular, font-style: normal), //regular
//	Realtext-3:             (name: 'Realtext-Italic',               file: 'realtextpro/3A3B10_3_0',    font-weight: $font-weight-regular, font-style: normal), //regular italic
//	Realtext-4:             (name: 'Realtext-Bold',                 file: 'realtextpro/3A3B10_4_0',    font-weight: $font-weight-regular, font-style: normal), //bold
//	Realtext-5:             (name: 'Realtext-BoldItalic',           file: 'realtextpro/3A3B10_5_0',    font-weight: $font-weight-regular, font-style: normal)  //bold italic
//);

$fonts: (
	Manrope-0:             (name: 'Manrope',                      file: 'manrope/Manrope-Light',           font-weight: $font-weight-light, font-style: normal), //light
	Manrope-1:             (name: 'Manrope',                      file: 'manrope/Manrope-Light',           font-weight: $font-weight-light, font-style: italic), //light italic
	Manrope-2:             (name: 'Manrope',                      file: 'manrope/Manrope-Regular',         font-weight: $font-weight-regular, font-style: normal), //regular
	Manrope-3:             (name: 'Manrope',                      file: 'manrope/Manrope-Regular',         font-weight: $font-weight-regular, font-style: italic), //regular italic
	Manrope-4:             (name: 'Manrope',                      file: 'manrope/Manrope-Bold',            font-weight: $font-weight-bold, font-style: normal), //bold
	Manrope-5:             (name: 'Manrope',                      file: 'manrope/Manrope-Bold',            font-weight: $font-weight-bold, font-style: italic),  //bold italic
	Manrope-6:             (name: 'Manrope',                      file: 'manrope/Manrope-Medium',            font-weight: $font-weight-medium, font-style: normal), //semi bold
	Manrope-7:             (name: 'Manrope',                      file: 'manrope/Manrope-Medium',            font-weight: $font-weight-medium, font-style: italic),  //semi bold italic
	Manrope-8:             (name: 'Manrope',                      file: 'manrope/Manrope-SemiBold',            font-weight: $font-weight-semi-bold, font-style: normal), //semi bold
	Manrope-9:             (name: 'Manrope',                      file: 'manrope/Manrope-SemiBold',            font-weight: $font-weight-semi-bold, font-style: italic)  //semi bold italic
);

@each $fontId, $fontData in $fonts {
	@font-face {
		font-family: map_get($fontData, name);
		src:    url($font-path + map_get($fontData, file) + '.woff2') format('woff2');
		//url($font-path + map_get($fontData, file) + '.woff' + '?' + $font-version) format('woff');

		font-weight: map_get($fontData, font-weight);
		font-style:  map_get($fontData, font-style);
		font-display: swap;
	}
}

$font-sans: 'Manrope', sans-serif;

//$font-sans: 'Realtext', sans-serif;
//$font-sans-italic: 'Realtext-Italic', sans-serif;
//$font-sans-light: 'Realtext-Light', sans-serif;
//$font-sans-light-italic: 'Realtext-LightItalic', sans-serif;
//$font-sans-bold: 'Realtext-Bold', sans-serif;
//$font-sans-bolditalic: 'Realtext-BoldItalic', sans-serif;

@mixin font-light() {
	font-family: $font-sans;
	font-weight: $font-weight-light;
}
%font-light {
	@include font-light;
}

@mixin font-reg() {
	font-family: $font-sans;
	font-weight: $font-weight-regular;
}
%font-reg {
	@include font-reg;
}

@mixin font-bold() {
	font-family: $font-sans;
	font-weight: $font-weight-bold;
}
%font-bold {
	@include font-bold;
}

@mixin font-semi-bold() {
	font-family: $font-sans;
	font-weight: $font-weight-semi-bold;
}
%font-semi-bold {
	@include font-semi-bold;
}

@mixin font-medium() {
	font-family: $font-sans;
	font-weight: $font-weight-medium;
}
%font-medium {
	@include font-medium;
}


/**
 * FONT SIZE MIXINS
 **/
@mixin font-size-h1() {
	//font-size: min(max(32px, 4vw), 60px);

	@include font-size(3.2, 3.8);

	@include respond-to(tablet) {
		@include font-size(4, 4.8);
	}
	@include respond-to(display) {
		@include font-size(6, 6.8, -10);
	}
}
@mixin font-size-h2() {
	@include font-size(3.2, 3.8);
	@include respond-to(display) {
		@include font-size(4, 4.8);
	}
}
@mixin font-size-h3() {
	@include font-size(2.4, 2.8);
	@include respond-to(display) {
		@include font-size(2.6, 3.5);
	}
}
@mixin font-size-h4() {
	@include font-size(1.8, 2.2);
	@include respond-to(display) {
		@include font-size(2, 2.6);
	}
}
@mixin font-size-h5() {
	@include font-size(1.6, 2.2);
	@include respond-to(display) {
		@include font-size(1.8, 2.2);
	}
}
@mixin font-size-h6() {
	@include font-size(1.4, 1.8);
	@include respond-to(display) {
		@include font-size(1.6, 2.2);
	}
}

@mixin font-size-navigation() {
	@include font-size(1.7, 2.2, 25);
	@include respond-to(display) {
		@include font-size(2.5, 3.0, 25);
	}
}
@mixin font-size-navigation-secondary() {
	@include font-size(1.7, 2.2, -10);
	@include respond-to(display) {
		@include font-size(1.8, 2.8, -10);
	}
}

@mixin font-size-navigation-clickable() {
	@include font-size(1.5 ,2.6, -20);
}

@mixin font-size-navigation-clickable-small() {
	@include font-size(1.5 ,2.6, -20);
}

@mixin font-size-navigation-clickable-second() {
	@include font-size(1.7, 3.8, -20);
}


@mixin font-size-navigation-breadcrumb() {
	@include font-size(1.2, 1.6, 25);
	@include respond-to(display) {
		@include font-size(1.4, 2.2, 25);
	}
}

@mixin font-size-button() {
	@include font-size(1.6, 2.4, 25);
	@include respond-to(display) {
		@include font-size(1.6, 2.4, 25);
	}
}

@mixin font-size-pagination() {
	@include font-size(1.6, 2.4, 25);
	@include respond-to(display) {
		@include font-size(2.0, 2.8, 25);
	}
}

@mixin font-size-body() {
	@include font-size(1.6, 2.4, 25);
	@include respond-to(display) {
		@include font-size(2.2, 3.3);
	}
}

@mixin font-size-body-lead() {
	@include font-size(1.8, 2.4);
	@include respond-to(display) {
		@include font-size(2.5, 3.6);
	}
}

@mixin font-size-body-medium() {
	@include font-size(1.8, 2.4);
	@include respond-to(display) {
		@include font-size(1.8, 2.4);
	}
}

@mixin font-size-body-small() {
	@include font-size(1.4, 2.2);
	@include respond-to(display) {
		@include font-size(1.5, 2.2);
	}
}

@mixin font-size-body-mini() {
	@include font-size(1.2, 2.0);
	@include respond-to(display) {
		@include font-size(1.2, 2.0);
	}
}

@mixin font-size-subline() {
	@include font-size(1.8, 2.4, 25);
	@include respond-to(display) {
		@include font-size(1.6, 2.2, 25);
	}
}

@mixin font-size-category() {
	@include respond-to(display) {
		@include font-size(1.6, 2.2, 25);
	}
}

@mixin font-size-quote() {
	@include font-size(2.6, 3.6);
	@include respond-to(display) {
		@include font-size(4.6, 5.6);
	}
}
@mixin font-size-quote-small() {
	@include font-size(2.2, 3.2);
	@include respond-to(display) {
		@include font-size(3.6, 4.6);
	}
}

@mixin font-size-footer() {
	@include font-size(1.4, 2.3, 0);
	//@include respond-to(tablet) {
	//	@include font-size(1.2, 2.0, 0);
	//}
}

@mixin font-size-rangeslider-value() {
	@include font-size(3.2, 3.8);
	@include respond-to(display) {
		@include font-size(3.8, 4.6);
	}
}

@mixin font-size-ribbon() {
	@include font-size(1.4, 1.8, 0);
	//@include respond-to(tablet) {
	//	@include font-size(1.2, 2.0, 0);
	//}
}

@mixin font-size-pricingcompare-value() {
	@include font-size(3.2, 3.8);

	@include respond-to(tablet) {
		@include font-size(4, 4.8);
	}
	@include respond-to(display) {
		@include font-size(5.2, 5.8);
	}



}


/**
 * FONT STYLE MIXINS
 */



@mixin font-style-body() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body;
}
@mixin font-style-body-lead() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body-lead;
}
@mixin font-style-body-medium() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body-medium;
}
@mixin font-style-body-small() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body-small;
}
@mixin font-style-body-mini() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-body-mini;
}
@mixin font-style-headline-1() {
	@extend %font-bold;
	//include font-semi-bold;
	@include font-size-h1;
}
@mixin font-style-headline-2() {
	@extend %font-semi-bold;
	//include font-bold;
	@include font-size-h2;
}
@mixin font-style-headline-3() {
	@extend %font-semi-bold;
	//include font-bold;
	@include font-size-h3;
}
@mixin font-style-headline-4() {
	@extend %font-semi-bold;
	//include font-bold;
	@include font-size-h4;
}
@mixin font-style-headline-5() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-h5;
}
@mixin font-style-headline-6() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-h6;
}
@mixin font-style-navigation() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-navigation;
}
@mixin font-style-navigation-secondary() {
	@extend %font-medium;
	//include font-bold;
	@include font-size-navigation-secondary;
}
@mixin font-style-navigation-clickable() {
	@extend %font-bold;
	@include font-size-navigation-clickable;
}
@mixin font-style-navigation-clickable-small() {
	@extend %font-bold;
	@include font-size-navigation-clickable-small;
}

@mixin font-style-navigation-clickable-second() {
	@extend %font-bold;
	@include font-size-navigation-clickable-second;
}
@mixin font-style-navigation-clickable-thin() {
	@extend %font-reg;
	@include font-size-navigation-clickable;
}

@mixin font-style-navigation-breadcrumb() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-navigation-breadcrumb;
}

@mixin font-style-button() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-button;
	text-transform: uppercase;
}
@mixin font-style-pagination() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-pagination;
	text-transform: uppercase;
}
@mixin font-style-button-small() {
	@extend %font-semi-bold;
	//include font-bold;
	@include font-size-body-small;
	text-transform: uppercase;
}
@mixin font-style-subline() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-subline;
	text-transform: uppercase;
}
@mixin font-style-quote-bold() {
	@extend %font-bold;
	@include font-size-quote;
}
@mixin font-style-quote() {
	@extend %font-light;
	@include font-size-quote;
}
@mixin font-style-quote-small() {
	@extend %font-light;
	@include font-size-quote-small;
}
@mixin font-style-pricing() {
	@extend %font-semi-bold;
	@include font-size-quote;
}
@mixin font-style-category() {
	@extend %font-bold;
	@include font-size-category;
	text-transform: uppercase;
}
@mixin font-style-cta() {
	@extend %font-semi-bold;
	@include font-size-quote;
}
@mixin font-style-footer() {
	@extend %font-reg;
	//include font-reg;
	@include font-size-footer;
}
@mixin font-style-rangeslider-value() {
	@extend %font-semi-bold;
	//include font-bold;
	@include font-size-rangeslider-value;
}
@mixin font-style-ribbon() {
	@extend %font-bold;
	//include font-bold;
	@include font-size-ribbon;
}
@mixin font-style-pricingcompare-value() {
	@extend %font-bold;
	@include font-size-pricingcompare-value;
}