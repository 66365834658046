.seperator {
	$self: &;

	margin: 3rem 0 4rem;

	&__line {
		--line-color: var(--bgc);
		width: 100%;
		height: 0.2rem;
		background: rgba(var(--line-color), 1);
	}

}