.social-link,
.social-share {
	$self: &;

	--color: rgba(var(--theme-color-font-std), 0.5);
	--color-hover: rgba(var(--theme-color-font-link), 1);
	--size: 4.2rem;

	display: flex;
	justify-content: space-between;
	margin: 2rem 0;

	&--text {
		display: block;
		margin-top: 0;

	}

	&__item {
		transition: all $tt-std ease;

		display: flex;
		align-items: center;
		justify-content: center;

		width: var(--size);
		height: var(--size);

		border: 0.2rem solid var(--color);


		svg {
			width: 60%;
			height: 60%;
			fill: var(--color);
		}

		&:hover {
			border-color: var(--color-hover);
			background: var(--color-hover);
			svg {
				fill: var(--color-white);
			}
		}

		#{ $self }--text & {
			border: 0;
			border-bottom: 0.2rem solid transparent;
			width: auto;
			height: auto;
			display: inline-block;



			color: var(--color);
			background: transparent;

			&:hover {
				border-color: var(--color-hover);
				color: var(--color-hover);
			}
		}

	}
}