.imagegrid {
	$self: &;

	padding-top: var(--layout-grid-bg-padding-top, inherit);
	padding-bottom: var(--layout-grid-bg-padding-bottom, inherit);

	&__container {
		@extend %container;
		@extend %grid;



		#{$self}--1 & {
			grid-template-rows: auto auto;
			grid-template-areas:
				'p0 p0 p0 p0 p0 p0 p0 p0 p2 p2 p2 p2'
				'.  .  .  p1 p1 p1 p1 p1 p2 p2 p2 p2'
			;
		}
	}

	&--1 {


	}

	&__item-1 {
		#{$self}--1 & {
			grid-area: p0;
		}
	}

	&__item-2 {
		#{$self}--1 & {
			grid-area: p1;
		}
	}

	&__item-3 {
		#{$self}--1 & {
			align-self: center;
			grid-area: p2;
		}
	}

}